import React from 'react'
import FilteredCars from '../../components/FilteredCars'
import Sort from '../../components/Sort'
import Carss from '../../components/FilterCars'


const Cars = () => {

	return (
		<>
			<Carss />
		</>
	)
}

export default Cars