export const ConList = [
    {
        id: 1,
        title: 'Возраст больше 21 года',
        url: '/img/21.png'
    },
    {
        id: 2,
        title: 'Водительский стаж свыше 3 лет.', 
        url: '/img/wheel.png'
    },
    {
        id: 3,
        title: 'Права/техпаспорт.', 
        url: '/img/IDCard.png'
    },
    {
        id: 4,
        title: 'Залог от 300$ либо паспорт', 
        url: '/img/HandCoin.png'
    },
]
export const ConList2 = [
    {
        id:1 , 
        text: 'В отличие от тех же такси, вы не ограничены – время и арендованная машина строго в ваших руках. Аренда автомобиля в Бишкеке становится постепенно популярной, ведь это отличный вариант для тех, кто решил выехать за пределы города с друзьями.',
        image: '/img/Girl.png'
    },
    {
        id:2, 
        text: 'Прокат авто в Кыргызстане решает большое количество распространенных рутинных проблем. Теперь вам не нужно беспокоиться об уходе или дорогостоящем ремонте автомобиля. А все технические хлопоты и заботы об обслуживании оставьте нам.',
        image: '/img/Boy.png'
    },
]