export const contactList = [
    {
        id: 1,
        name: 'Айвин',
        image: '/img/iwinAvatar.png',
        desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi repudiandae, dolore alias consectetur",
        number: '0505-55-05-55',
        gmail: 'iwin@gmail.com',
        whats: '0777-77-77-97',
    },
    {
        id: 2,
        name: 'Максат',
        image: '/img/boyAvatar.png',
        desc: "Lorem ipsum dolor sit amt. Modi repudiands velit impedit voluptatem esse eos sequi.",
        number: '0505-55-05-55',
        gmail: 'maks@gmail.com',
        whats: '0777-77-77-97',
    },
    {
        id: 3,
        name: 'Аселя',
        image: '/img/aselyaAvatar.png',
        desc: "Lorem ipsum dolor sit dolore alias consectetur quas velit impedit voluptatem esse eos sequi.",
        number: '0505-55-05-55',
        gmail: 'Aselyan@gmail.com',
        whats: '0777-77-77-97',
    },
]