export const HTUList = [
    {
        id: 1,
        title: 'Забронируйте',
        desc: 'Откройте приложение UNAAPARK carsharing. Выберите автомобиль на карте, нажмите кнопку «забронировать». После этого у вас будет 10 бесплатных минут дойти до автомобиля.'
    },
    {
        id: 2,
        title: 'Осмотрите машину',
        desc: 'Оцените её внешний вид и внутреннее состояние. Загрузите фотографии на предмет повреждений и отметьте наличие документов: в бардачке должны лежать документы.',
        desc2: 'Подошли к машине?'
    },
    {
        id: 3,
        title: 'Начните поездку',
        desc: 'Подпишите акт приема-передачи автомобиля нажав OK. Теперь вы можете заводить автомобиль и начинать поездку.'
    },
    {
        id: 4,
        title: 'Завершите поездку',
        desc: 'Для завершения поездки выключите зажигание, переведите коробку передачи в положение «P», выключите свет, закройте окна и двери. Нажмите завершить'
    },

]