export const steps_list = [
  {
    id: 1,
    title: '1',
    heading: 'Регистрация',
    desc: 'Скачайте мобильное приложение. Подготовьте необходимые документы и пройдите регистрацию. Весь процесс займет не более 5 минут'
  },
  {
    id: 2,
    title: '2',
    heading: 'Верификация',
    desc: 'В течение одного рабочего дня мы проверим документы и активируем аккаунт'
  },
  {
    id: 3,
    title: '3',
    heading: 'Выбор тарифа',
    desc: 'Выберите подходящий под вас тариф, сейчас они отличаются финансовой ответственностью'
  },
  {
    id: 4,
    title: '4',
    heading: 'Пополнение баланса',
    desc: 'Пополните баланс аккаунта исходя из желаемого тарифа с помощью банковской карты - через приложение, а также - терминалов paynet'
  },
  {
    id: 5,
    title: '5',
    heading: 'Начните пользоваться',
    desc: 'После этих шагов, вы можете смело арендовать ближайший автомобиль amiGO'
  }
]